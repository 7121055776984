import React from 'react';
import { List } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    '& > li': {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.1),
      '&:before': {
        content: "'•'",
        marginRight: theme.spacing(1),
        marginBottom: 'auto',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.body1.fontWeight,
        fontSize: theme.typography.caption.fontSize,
      },
      '& ul, ol': {
        marginTop: '0.35em',
        marginBottom: 0,
      },
      '& p': {
        textAlign: 'left',
        marginBottom: 0,
      },
    },
  },
  ordered: {
    counterReset: 'section',
    '& > li': {
      '&:before': {
        counterIncrement: 'section',
        content: "counters(section, '.')",
        backgroundColor: theme.palette.embossed,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
}));

interface SimpleListProps {
  className?: string;
  ordered?: boolean;
  children: React.ReactNode;
  subheader?: string;
}

function SimpleList({
  className,
  ordered = false,
  subheader,
  children,
}: SimpleListProps) {
  const { classes } = useStyles();
  return (
    <List
      {...{
        subheader,
        children,
      }}
      className={classNames(classes.root, className, {
        [classes.ordered]: ordered,
      })}
      component={ordered ? 'ol' : 'ul'}
      dense
      disablePadding
    />
  );
}

export default SimpleList;
