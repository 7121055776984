import {
  Button,
  Divider,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import React from 'react'
import Layout from '../../components/Layout'
import GuideHeader from '../../components/navigation/GuideHeader'
import Link from '../../components/navigation/Link'
import SeoHeader from '../../components/SeoHeader'
import SimpleList from '../../components/SimpleList'

const layout = {
  SeoProps: {
    title: 'Legal Notice',
  },
}

const Address = () => {
  const [address, setAddress] = React.useState(null)

  return address ? (
    <Typography paragraph sx={{ whiteSpace: 'pre-line' }}>
      {address}
    </Typography>
  ) : (
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        setAddress(
          window.atob(
            'TWFudWVsIEhhYWcKTmVja2Fyc3RyLiAxNjQKNzAxOTAgU3R1dHRnYXJ0Ckdlcm1hbnkKcG9zdG1hc3RlcitkaXNjcmV0aXplQGhhYWcuc28=',
          ),
        )
      }}
    >
      Show Address
    </Button>
  )
}

function LegalPage({ location }) {
  return (
    <Layout location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <GuideHeader title="Legal Notice" />
      <Divider />

      <Typography variant="h5" gutterBottom>
        Disclaimer
      </Typography>

      <Typography paragraph>
        NCSOFT, the interlocking NC logo, ArenaNet, Guild Wars, Guild Wars
        Factions, Guild Wars Nightfall, Guild Wars: Eye of the North, Guild Wars
        2, and all associated logos and designs are trademarks or registered
        trademarks of NCSOFT Corporation. All other trademarks are the property
        of their respective owners.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom>
        Terms of Service
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Terms
      </Typography>

      <Typography paragraph>
        By accessing the website at https://www.discretize.eu, you are agreeing to
        be bound by these terms of service, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this website are protected by applicable copyright and trademark law.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Use License
      </Typography>

      <Typography paragraph>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on Discretize&#39;s website for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license you may not:
      </Typography>

      <SimpleList>
        <ListItem>
          <ListItemText>modify or copy the materials;</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            use the materials for any commercial purpose, or for any public
            display (commercial or non-commercial);
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            attempt to decompile or reverse engineer any software contained on
            Discretize&#39;s website; remove any copyright or other proprietary
            notations from the materials;
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            or transfer the materials to another person or &quot;mirror&quot;
            the materials on any other server.
          </ListItemText>
        </ListItem>
      </SimpleList>

      <Typography paragraph>
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by Discretize at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Disclaimer
      </Typography>

      <Typography paragraph>
        The materials on Discretize&#39;s website are provided on an &#39;as
        is&#39; basis. Discretize makes no warranties, expressed or implied, and
        hereby disclaims and negates all other warranties including, without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, Discretize does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its website or otherwise
        relating to such materials or on any sites linked to this site.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Limitations
      </Typography>

      <Typography paragraph>
        In no event shall Discretize or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        the materials on Discretize&#39;s website, even if Discretize or a
        Discretize authorized representative has been notified orally or in
        writing of the possibility of such damage. Because some jurisdictions do
        not allow limitations on implied warranties, or limitations of liability
        for consequential or incidental damages, these limitations may not apply
        to you.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Accuracy of materials
      </Typography>

      <Typography paragraph>
        The materials appearing on Discretize website could include technical,
        typographical, or photographic errors. Discretize does not warrant that
        any of the materials on its website are accurate, complete or current.
        Discretize may make changes to the materials contained on its website at
        any time without notice. However Discretize does not make any commitment
        to update the materials.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Links
      </Typography>

      <Typography paragraph>
        Discretize has not reviewed all of the sites linked to its website and
        is not responsible for the contents of any such linked site. The
        inclusion of any link does not imply endorsement by Discretize of the
        site. Use of any such linked website is at the user&#39;s own risk.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Modifications
      </Typography>

      <Typography paragraph>
        Discretize may revise these terms of service for its website at any time
        without notice. By using this website you are agreeing to be bound by
        the then current version of these terms of service.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Governing Law
      </Typography>

      <Typography paragraph>
        These terms and conditions are governed by and construed in accordance
        with the laws of Germany and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Information according to §5 TMG
      </Typography>

      <Address />

      <Divider />

      <Typography variant="h5" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography paragraph>
        Your privacy is important to us. It is Discretize&#39;s policy to
        respect your privacy regarding any information we may collect from you
        across our website, https://www.discretize.eu.
      </Typography>

      <Typography paragraph>
        We don’t ask for your personal information unless we truly need it. When
        we do, we’ll only collect what we need by fair and lawful means and,
        where appropriate, with your knowledge or consent. We’ll also let you
        know why we’re collecting it and how it will be used.
      </Typography>

      <Typography paragraph>
        We don’t share your personal information with third-parties, except
        where required by law. We will only retain personal information for as
        long as necessary to provide you with a service.
      </Typography>

      <Typography paragraph>
        We don’t store your personal information on our servers unless it’s
        required for providing a service to you. What we store, we’ll protect
        within commercially acceptable means to protect your personal
        information from loss or theft, as well as unauthorized access,
        disclosure, copying, use or modification.
      </Typography>
    </Layout>
  )
}

export default LegalPage
